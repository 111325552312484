import React from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import HeroWithButtons from '../../components/HeroWithButtons/HeroWithButtons';
import StreamField from '../../components/StreamField/StreamField';
import Header from '../../components/Header/Header';
import GlobalNotice from '../../components/GlobalNotice/GlobalNotice';
import Footer from '../../components/Footer/Footer';
import HeroWithSignup from '../../components/HeroWithSignup';

import s from './SiteStartPage.module.scss';

const SiteStartPage = ({ siteSetting = {}, hero = {}, content = [] }) => {
    return (
        <div className={s.Container}>
            {siteSetting && siteSetting.header && (
                <Header {...siteSetting.header} />
            )}
            <main className={s.Main}>
                {siteSetting && siteSetting.globalNotice && (
                    <GlobalNotice {...siteSetting.globalNotice} />
                )}
                {hero && hero.component === 'hero_with_buttons' && (
                    <HeroWithButtons {...hero} />
                )}
                {hero && hero.component === 'hero_with_signup' && (
                    <HeroWithSignup {...hero} />
                )}

                {content && (
                    <StreamField
                        items={content}
                        heroWithButtons={hero.component === 'hero_with_buttons'}
                    />
                )}
            </main>
            {siteSetting && siteSetting.footer && (
                <Footer {...siteSetting.footer} />
            )}
        </div>
    );
};

SiteStartPage.propTypes = {
    siteSetting: PropTypes.object,
    hero: PropTypes.object,
    content: PropTypes.array,
};

export default basePageWrap(SiteStartPage);
