import React from 'react';
import PropTypes from 'prop-types';

import Hero from '../Hero';
import CardSignup from '../CardSignup';
import CardWithAnimatedIcon from '../CardWithAnimatedIcon';

import styles from './HeroWithSignup.module.scss';

const HeroWithSignup = ({
    title = '',
    text = '',
    subTitle = '',
    image = {},
    signUp = {},
    ctaButtons = [],
}) => {
    return (
        <>
            <Hero
                {...{ image, text, title }}
                modifiers={['withOffsetContent']}
            />
            <div className={styles['Hero__OffsetContent']}>
                <CardSignup {...signUp} />
                {subTitle && (
                    <h3 className={styles['Hero__Title']}>{subTitle}</h3>
                )}
                <div className={styles['Hero__ButtonContainer']}>
                    {!!ctaButtons &&
                        ctaButtons.map((button, index) => (
                            <CardWithAnimatedIcon
                                key={index}
                                index={index}
                                link={button.link}
                                {...button}
                            />
                        ))}
                </div>
            </div>
        </>
    );
};

HeroWithSignup.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
        renditions: PropTypes.shape({
            mobile: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            mobile2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
    }),
    signUp: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        input: PropTypes.object,
        selectButtons: PropTypes.array,
        button: PropTypes.object,
    }),
};

export default HeroWithSignup;
