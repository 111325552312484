import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';

import Image from '../Image';
import styles from './Hero.module.scss';

const Hero = ({
    title = '',
    text = '',
    size = '',
    image = {},
    modifiers = [],
}) => {
    const classes = classNames(
        styles['Hero'],
        !!size && styles['Hero--' + ucFirst(size)],
        modifiers.map((modifier) => styles['Hero--' + ucFirst(modifier)] + ' ')
    );

    return (
        <div className={classes}>
            <div className={styles['Hero__BackgroundImage']}>
                {image && image.url && <Image {...{ image }} priority={true} />}
                <div className={styles['Hero__Gradient']}></div>
            </div>
            <div className={styles['Hero__Inner']}>
                {!!title && (
                    <h1
                        className={styles['Hero__Title']}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                )}
                {!!text && (
                    <div
                        className={styles['Hero__Text']}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
            </div>
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        renditions: PropTypes.shape({
            mobile: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            mobile2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
        focal: PropTypes.shape({
            x: PropTypes.string,
            y: PropTypes.string,
        }),
        altText: PropTypes.string,
    }),
    bulletItems: PropTypes.arrayOf(PropTypes.string),
    size: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Hero;
